const colors = {
  200: "success",
  201: "success",
  401: "warning",
  403: "info",
  404: "warning",
  409: "warning"
}

const messages = {
  error: {
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Elemento no encontrado",
      en_message: "Element not found"
    },
    451: {
      es_title: "No permitido",
      en_title: "Not allowed",
      es_message: "No puedes subir contenido inapropiado",
      en_message: "You cannot upload inappropiate content"
    },
    452: {
      es_title: "No permitido",
      en_title: "Not allowed",
      es_message: "No puedes subir contenido ofensivo",
      en_message: "You cannot upload offensive content"
    },
    500: {
      es_title: "Error interno",
      en_title: "Internal error",
      es_message: "Ha ocurrido un error, por favor recargue la página",
      en_message: "An error has occurred, please reload the page"
    }
  },
  post: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu publicación fue creada",
      en_message: "Your post was created"
    }
  },
  "share-post": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "La publicación fue compartida",
      en_message: "Post was shared"
    },
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Ocurrió un error",
      en_message: "An error occurred"
    }
  },
  "comment-story": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu comentario ha sido enviado",
      en_message: "Your comment was sent"
    },
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Ocurrió un error",
      en_message: "An error occurred"
    }
  },
  story: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu momento fue creado",
      en_message: "Your moment was created"
    }
  },
  "post-delete": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu publicación fue eliminada",
      en_message: "Your post was deleted"
    }
  },
  "video-cancel": {
    499: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu subida de video fue cancelada",
      en_message: "Your video upload was cancelled"
    }
  },
  "story-delete": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu momento fue eliminado",
      en_message: "Your moment was deleted"
    }
  },
  "comment-delete": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu comentario fue eliminado",
      en_message: "Your comment was deleted"
    }
  },
  "abandon-community": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Abandonaste la comunidad",
      en_message: "You abandoned the community"
    }
  },
  login: {
    200: {
      es_title: "Bienvenido/a",
      en_title: "Welcome",
      es_message: "Es un gusto tenerte de regreso",
      en_message: "It's a pleasure to have you back"
    },
    401: {
      es_title: "Usuario no activo",
      en_title: "User not active",
      es_message: "No puedes acceder porque tu usuario ha sido desactivado",
      en_message: "You cannot log in because your user is deactivated"
    },
    402: {
      es_title: "Contraseña incorrecta",
      en_title: "Incorrect password",
      es_message: "Contraseña inválida, intenta de nuevo",
      en_message: "Invalid password, try again"
    },
    403: {
      es_title: "Demasiados intentos",
      en_title: "Too many attempts",
      es_message:
        "Hemos detectado demasiados intentos de inicio fallido, por favor intenta mas tarde",
      en_message:
        "We have detected too many failed log in attempts, please try again later"
    },
    404: {
      es_title: "Usuario no encontrado",
      en_title: "User not found",
      es_message: "No hay un usuario registrado con el correo introducido",
      en_message: "There is no registered user with that mail"
    },
    400: {
      es_title: "Campos faltantes",
      en_title: "Missing fields",
      es_message: "Ingresa todos los campos obligatorios",
      en_message: "Fill all the required fields"
    }
  },
  'must-register': {
    200: {
      es_title: "¡Estás casi listo/a!",
      en_title: "You're almost done!",
      es_message: "Solo falta completar tu registro",
      en_message: "You just have to finish your register"
    },
  },
  register: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Registro completado con éxito, inicia sesión",
      en_message: "Registration completed successfully, log in now"
    },
    409: {
      es_title: "Elemento duplicado",
      en_title: "Duplicated element",
      es_message: "El correo electrónico ya está en uso",
      en_message: "This e-mail is already in use"
    }
  },
  recovery: {
    200: {
      es_title: "Revisa tu correo",
      en_title: "Check your e-mail",
      es_message: "Hemos enviado un correo con el código de verificación",
      en_message: "We have sent an e-mail with the verification code"
    },
    404: {
      es_title: "Usuario no encontrado",
      en_title: "User not found",
      es_message: "No hay un usuario registrado con el correo introducido",
      en_message: "No user found with the entered e-mail"
    }
  },
  master: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Elemento creado con éxito",
      en_message: "Element created successfully"
    },
    409: {
      es_title: "Elemento duplicado",
      en_title: "Duplicated element",
      es_message: "Ya existe este elemento",
      en_message: "This element already exists"
    }
  },
  "master-update": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Elemento actualizado con éxito",
      en_message: "Element updated successfully"
    },
    409: {
      es_title: "Elemento duplicado",
      en_title: "Duplicated element",
      es_message: "Ya existe este elemento",
      en_message: "This element already exists"
    }
  },
  "master-status": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Estado del elemento actualizado con éxito",
      en_message: "Element status updated successfully"
    }
  },
  "master-status-delete": {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "El elemento fue eliminado",
      en_message: "The element was deleted"
    }
  },
  logout: {
    200: {
      es_title: "¡Hasta luego!",
      en_title: "Until next time!",
      es_message: "Esperamos verte pronto por aquí",
      en_message: "We hope to see you around soon"
    }
  },
  changePassword: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Su contraseña ha sido cambiada con éxito",
      en_message: "Your password was changed successfully"
    },
    401: {
      es_title: "Contraseña incorrecta",
      en_title: "Invalid password",
      es_message: "Contraseña actual invalida, intente de nuevo",
      en_message: "Current password is not valid, try again"
    }
  },
  setPassword: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Su contraseña ha sido restablecida con éxito",
      en_message: "Your password was reset successfully"
    },
    404: {
      es_title: "Token inválido",
      en_title: "Invalid token",
      es_message: "El token de recuperación es inválido, verifique su URL",
      es_message: "Recovery token is not valid, check your URL"
    },
    400: {
      es_title: "Token expirado",
      en_title: "Expired token",
      es_message: "El token de recuperación ya no está disponible",
      en_message: "Recovery token is not available anymore"
    },
    403: {
      es_title: "Token no válido",
      en_title: "Invalid token",
      es_message:
        "El token de recuperación ya no es válido. Por favor inicia el proceso nuevamente",
      en_message: "Recovery token is not valid. Please start the process again"
    }
  },
  resend: {
    200: {
      es_title: "Correo enviado",
      en_title: "E-mail sent",
      es_message: "Tu correo de verificación ha sido enviado con éxito",
      en_message: "Your verification e-mail was sent successfully"
    },
    404: {
      es_title: "Usuario no encontrado",
      en_title: "User not found",
      es_message: "No hay un usuario registrado con el correo introducido",
      en_message: "No user found with that e-mail"
    }
  },
  upload: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Su foto se ha actualizado con éxito",
      en_message: "Your pciture was updated successfully"
    }
  },
  notificationReaded: {
    200: {
      es_title: "Notificaciones leídas",
      en_title: "Notifications read",
      es_message: "Todas las notificaciones han sido marcadas como leídas",
      en_message: "All notifications have been marked as read",
      color: "info"
    }
  },
  followRequestAccepted: {
    200: {
      es_title: "Solicitud de seguimiento aceptada",
      en_title: "Follow request accepted",
      es_message: "Has aceptado la solicitud de seguimiento",
      en_message: "You've accepted the follow request",
      color: "info"
    }
  },
  followRequestRejected: {
    200: {
      es_title: "Solicitud de seguimiento rechazada",
      en_title: "Follow request rejected",
      es_message: "Has rechazado la solicitud de seguimiento",
      en_message: "You've rejected the follow request",
      color: "info"
    }
  },
  subscribeUser: {
    200: {
      es_title: "Subscripción realizada",
      en_title: "Subscription completed",
      es_message: "Ahora recibirás notificaciones de este usuario",
      en_message: "Now you'll receive notifications from this user",
      color: "info"
    }
  },
  unsubscribeUser: {
    200: {
      es_title: "Subscripción cancelada",
      en_title: "Subscription cancelled",
      es_message: "Ya no recibirás más notificaciones de este usuario",
      en_message: "Now you wont receive notifications from this user",
      color: "info"
    }
  },
  report: {
    200: {
      es_title: "Gracias por tu denuncia",
      en_title: "Thank you for your report",
      es_message:
        "Analizaremos tu denuncia y te notificaremos vía correo de ser necesario",
      en_message:
        "We will analyze your report and notify you through e-mail if necessary"
    }
  },
  report_user: {
    409: {
      es_title: "Ya denunciaste a este usuario",
      en_title: "User already reported",
      es_message:
        "Analizaremos tu denuncia y te notificaremos vía correo de ser necesario",
      en_message:
        "We will analyze your report and notify you through e-mail if necessary"
    }
  },
  text_copied: {
    200: {
      es_title: "Copiado al portapapeles",
      en_title: "Copied to clipboard",
      es_message:
        "¡Ahora ve y comparte el link con tus amigos!",
      en_message:
        "Now go and share this link with your friends!"
    }
  },
  ban_votation: {
    200: {
      es_title: "Gracias por participar",
      en_title: "Thank you for participating",
      es_message:
        "Hemos recibido tu voto y serás notificado si el usuario es suspendido",
      en_message:
        "We have received your vote. You will be notified if the user is suspended"
    },
    409: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Ya has votado",
      en_message: "You have already voted"
    }
  },
  report_post: {
    409: {
      es_title: "Ya denunciaste esta publicación",
      en_title: "Post already reported",
      es_message:
        "Analizaremos tu denuncia y te notificaremos vía correo de ser necesario",
      en_message:
        "We will analyze your report and notify you through e-mail if necessary"
    }
  },
  room_user: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Usuario agregado a la comunidad",
      en_message: "User added to the community"
    },
    409: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Este usuario ya pertenece a la comunidad",
      en_message: "User already belongs to this community"
    }
  },
  block_user: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "El usuario fue bloqueado",
      en_message: "User was blocked"
    },
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Elemento no encontrado",
      en_message: "Element not found"
    }
  },
  unblock_user: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "El usuario fue desbloqueado",
      en_message: "User was unblocked"
    },
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Elemento no encontrado",
      en_message: "Element not found"
    }
  },
  user: {
    403: {
      es_title: "Error",
      en_title: "Error",
      es_message: "No puedes hacer eso",
      en_message: "You cannot do that"
    }
  },
  poll_answer: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu voto ha sido registrado",
      en_message: "Your vote was registered"
    },
    403: {
      es_title: "Error",
      en_title: "Error",
      es_message: "No puedes votar porque la encuesta ha finalizado",
      en_message: "You cannot vote because the poll was closed"
    }
  },
  channel_subscribed: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Te has suscrito correctamente al canal",
      en_message: "You've subscribed to the channel successfully"
    },
    409: {
      es_title: "¡Hey!",
      en_title: "Hey!",
      es_message: "Ya estabas suscrito a este canal",
      en_message: "You were already subscribed to this channel"
    },
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Elemento no encontrado",
      en_message: "Element not found"
    },
    500: {
      es_title: "Error interno",
      en_title: "Internal error",
      es_message: "Ha ocurrido un error, por favor recargue la página",
      en_message: "An error has occurred, please reload the page"
    }
  },
  verification_user: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Tu solicitud de verificación de perfil ha sido enviada",
      en_message: "Your verification request has been sent successfully"
    },
    409: {
      es_title: "¡Hey!",
      en_title: "Hey!",
      es_message: "Ya posees una solicitud de verificación de perfil en curso",
      en_message: "You already have a pending verification request"
    },
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Elemento no encontrado",
      en_message: "Element not found"
    },
    500: {
      es_title: "Error interno",
      en_title: "Internal error",
      es_message: "Ha ocurrido un error, por favor recargue la página",
      en_message: "An error has occurred, please reload the page"
    }
  },
  chat_delete: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Se ha eliminado correctamente el chat",
      en_message: "The chat has been deleted successfully"
    },
    400: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Hubo un error con la validación de los datos. Por favor intente de nuevo más tarde",
      en_message: "An error ocurred during your data validation. Please try again later"
    },
    404: {
      es_title: "Error",
      en_title: "Error",
      es_message: "Elemento no encontrado",
      en_message: "Element not found"
    },
    500: {
      es_title: "Error interno",
      en_title: "Internal error",
      es_message: "Ha ocurrido un error, por favor recargue la página",
      en_message: "An error has occurred, please reload the page"
    }
  },
  poll_end: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "La encuesta ha sido finalizada",
      en_message: "Your poll was closed"
    }
  },
  share_poll_results: {
    200: {
      es_title: "¡Éxito!",
      en_title: "Success!",
      es_message: "Los resultados de la encuesta fueron compartidos",
      en_message: "Poll results were shared"
    }
  }
}

export const notifyMessage = (code = 0, type = "error", locale) => {
  return {
    title:
      messages[type][code][`${locale}_title`] ||
      messages["error"][0][`${locale}_title`],
    message:
      messages[type][code][`${locale}_message`] ||
      messages["error"][0][`${locale}_message`],
    color: colors[code] || "warning"
  }
}
