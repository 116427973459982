import { notifyMessage } from "./responseMessages"

const getNotification = (notify, title, message, type) => {
  return notify[type](message, title, {
    timeout: 5000,
    showProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    titleMaxLength: 40,
    bodyMaxLength: 100,
    position: "rightBottom"
  })
}

const notification = (type, code, notify, locale = "en") => {
  if (code === 451 || code === 452) {
    let { title, message, color } = notifyMessage(code, "error", locale)
    return getNotification(notify, title, message, color)
  } else {
    let { title, message, color } = notifyMessage(code, type, locale)
    return getNotification(notify, title, message, color)
  }
}

export { notification }
